.details {
    &__container {
        height: 100%;
        display: flex;
        flex-direction: column;
    }

    &__content {
        height: 100%;
        overflow: auto;
    }
}

.listPage {
    &__container {
        height: 100%;
        display: flex;
        flex-direction: column;
    }

    &__toolbar {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0.5rem 1rem;
        border: 1px solid #ddd;
        background-color: #fff;
    }

    &__content {
        height: 100%;
        // height: auto;
        max-height: 100%;
        overflow: auto;
        padding: 0.5rem;
    }
}

.profile-top-header {

    &::before {
        background: linear-gradient(180deg, #e6f2ff, #b8d3ed);
    }
}

.qr_code_card {
    height: 68px;
    width: 68px;
    position: relative;
    overflow: hidden;

    & .e-qrcode {

        position: absolute;
        left: -6px;
    }
}

.e-badge {
    border: 1px solid var(--stroke-neutral-base);
}

.e-badge.e-badge-outline {
    border: 1px solid #bbb !important;
}

.e-badge.e-badge-outline.e-badge-primary {
    border: 1px solid #0078d6 !important;
    background-color: #fff !important;
    color: #0078d6 !important;
}

.e-badge.e-badge-md {
    font-size: 1em !important;
}

.e-badge.e-badge-border {
    background-color: transparent !important;
    border: 1px solid #999 !important;
    color: #666 !important;
}

.e-badge.e-badge-grey-border {
    background-color: rgba(0, 0, 0, .05) !important;
    border: 1px solid #999 !important;
    color: #333 !important;
}

.e-badge.e-badge-danger.e-badge-border {
    border: 1px solid #a80000 !important;
    color: #a80000 !important;
}

.e-badge.e-badge-primary.e-badge-border {
    border: 1px solid #0078d6 !important;
    color: #0078d6 !important;
}

.e-badge.e-badge-warning.e-badge-border {
    border: 1px solid #d83b01 !important;
    color: #d83b01 !important;
}

.e-badge.e-badge-high,
.e-badge.e-badge-High {
    background-color: #FF0000;
    color: #fff;
}

.e-badge.e-badge-medium,

.e-badge.e-badge-Medium {
    background-color: #FFA500;
    color: #fff;
}

.e-badge.e-badge-Low,
.e-badge.e-badge-low {
    background-color: #008000;
    color: #fff;
}

.e-badge.e-badge-Other,
.e-badge.e-badge-other {
    background-color: #004D40;
    color: #fff;
}

.e-badge.e-badge-active {
    background-color: #dcecf9;
    color: var(--raf-primary);
    border-color: var(--raf-primary) !important;
}

.e-badge.e-badge-circle {
    min-height: 16px;
    height: 16px;
    min-width: 16px;
    width: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 10px;
    line-height: normal;
    padding: 0 0.25rem;
}

.e-badge.e-badge-circle-lg {
    min-height: 20px;
    height: 20px;
    min-width: 20px;
    width: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    font-size: 0.8125rem;
}

.e-badge.e-badge-md {
    min-height: 1.25rem;
    height: 1.25rem;
    width: auto;
    min-width: 1.25rem;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0.125rem 0.25rem;
    line-height: 1.25rem;
}

.e-badge.e-badge-custom {
    font-size: 0.8125rem !important;
    border-radius: 10px;
    padding: 0.25rem 0.5rem !important;
}

.e-badge.e-badge-btn {
    height: 2rem;
    min-height: 2rem;
    line-height: 1.75rem;
    font-size: 0.875rem;
    border-radius: 0.25rem;
    padding: 0 0.5rem;
}

.e-badge.e-badge-btn-lg {
    height: 2.5rem;
    min-height: 2.5rem;
    line-height: 2.25rem;
    font-size: 1rem;
    border-radius: 0.5rem;
    padding: 0 1rem;

    &.minWidth {
        // min-width: 4.5rem;
        // text-align: center;
    }
}

.e-badge.e-badge-xlg {
    height: 1.625rem;
    min-height: 1.625rem;
    line-height: 1.625rem;
    font-size: 0.875rem !important;
    padding: 0 1rem !important;
    border-radius: 0.875rem;
}

.e-badge.e-badge-lg {
    height: 1.75rem;
    min-height: 1.75rem;
    line-height: 1.75rem;
    font-size: 0.875rem !important;
    text-transform: uppercase;
    padding: 0 1rem !important;
    border-radius: 0.875rem;
}

.e-badge-grey {
    background: #e0e0e0 !important;
    color: #333 !important;
}

.e-badge.e-badge-circle.e-small {
    min-height: 14px;
    height: 14px;
    min-width: 14px;
    width: 14px;
    font-size: 9px;
}

.e-badge.e-badge-circle.e-badge-sm-18 {
    min-height: 18px;
    height: 18px;
    min-width: 18px;
    width: 18px;
}

.e-badge.e-badge-overlap.e-badge-circle.e-small {
    top: -5px;
    transform: translateX(-60%);
    min-height: 14px;
    height: 14px;
    min-width: 14px;
    width: 14px;
    font-size: 9px;
}

.e-badge-lg {
    font-size: 0.75rem;
    padding: 0.25rem 0.75rem;
}

.raf_badge {
    padding: 0.1875rem 0.5rem;
    font-size: 0.75rem;
    line-height: 1rem;
    font-style: normal;
    border-radius: 99px;
    background-color: var(--surface-neutral-light);
    color: var(--content-neutral-base);
    border: 1px solid var(--stroke-neutral-base);
    display: block;
    font-weight: 400;

    &_square {
        border-radius: 0.25rem;
        padding: 8px;
        line-height: 1rem;
        height: 2rem;
        width: 2rem;
        display: flex;
        align-items: center;

        &_icon {
            font-size: 1rem;
            color: inherit;
        }

        &.raf_sm {
            padding: 4px;
            line-height: 1rem;
            height: 1.5rem;
            width: 1.5rem;
        }
    }

    &_text_square {
        border-radius: 0.5rem;
        padding: 0.375rem 0.5rem;
        line-height: 1.25rem;
        height: 2rem;
        display: flex;
        align-items: center;
    }

    &_card {
        border-radius: 0.5rem;
        background-color: var(--surface-neutral-base);
    }

    &.line_height_0 {
        line-height: normal;
    }

    &_text {
        font-size: 0.75rem;
        line-height: 1rem;
        font-style: normal;
        font-weight: 400;
    }

    &_icon {
        line-height: 0.875rem;
        padding-right: 0.25rem;
    }

    &_primary {
        background-color: var(--surface-brand-primary-light);
        color: var(--content-brand-primary-base);
        border-color: var(--primary-30);
    }

    &_secondary {
        background-color: var(--surface-brand-secondary-light);
        color: var(--content-brand-secondary-base);
        border-color: var(--secondary-30);

        &.semi_dark {
            background-color: var(--surface-brand-secondary-semi-dark);
            color: var(--content-neutral-white);
        }

        &.outline {
            background-color: var(--surface-neutral-light);
            color: var(--content-brand-secondary-base);
            border-color: var(--stroke-warning);
        }
    }

    &_quaternary {
        background-color: var(--surface-brand-quaternary-light);
        color: var(--content-brand-quaternary-dark);
        border-color: var(--quaternary-30);
    }

    &_tertiary {
        background-color: var(--surface-brand-tertiary-light);
        color: var(--content-brand-tertiary-base);
        border-color: var(--tertiary-30);
    }

    &_neutral {
        background-color: var(--surface-neutral-base);
        color: var(--content-neutral-base);
    }

    &_success,
    &_Active {
        background-color: var(--surface-state-success-light);
        color: var(--content-state-success-base);
        border-color: var(--success-30);

        & svg {
            & path {
                stroke: var(--content-state-success-base);
            }
        }
    }

    &_warning {
        background-color: var(--surface-state-warning-light);
        color: var(--content-state-warning-base);
        border-color: var(--warning-30);

        &_dark {
            color: var(--content-state-warning-dark);
            border: 1px solid var(--stroke-warning);
        }

        & svg {
            & path {
                stroke: var(--content-state-warning-base);
            }
        }
    }

    &_danger,
    &_Inactive {
        background-color: var(--surface-state-danger-light);
        color: var(--content-state-danger-base);
        border-color: var(--danger-30);

        & svg {
            & path {
                stroke: var(--content-state-danger-base);
            }
        }
    }

    &_draft {
        color: var(--content-neutral-dark);
        background-color: var(--surface-neutral-semi-dark);
        border-color: var(--neutral-30);
    }

    &_anchor,
    &_published {
        color: var(--content-anchor-dark);
        background-color: var(--surface-anchor-base);
        border-color: var(--anchor-30);
    }

    &_rgba {
        background-color: rgba($color: #fff, $alpha: 0.2);
        border-color: rgba($color: #fff, $alpha: 0.2);
    }

    &.raf_lg {
        padding: 0.25rem 0.5rem;
        font-size: 0.875rem;
        line-height: 1.25rem;
        padding-bottom: 0.1875rem;
        line-height: 1rem;
        font-size: 0.75rem;

        & .raf_badge_text {
            font-size: 0.875rem;
            line-height: 1.25rem;
        }
    }

    &.raf_xsm {
        padding-top: 0.125rem;
        padding-bottom: 0.125rem;
        font-size: 0.625rem;
        line-height: 0.875rem;
        padding: 0.125rem 0.5rem;
    }

    &.raf_20 {
        padding: 0 0.375rem;
        font-size: 0.75rem;
        line-height: 0.875rem;
        height: 1.25rem;
        min-height: 1.25rem;

        & .raf_badge_text {
            font-size: 0.75rem;
            line-height: 0.875rem;
            padding: 0.125rem 0;
        }
    }

    &.raf_18 {
        padding: 0 0.5rem;
        border-radius: 0.25;
        font-size: 0.625rem;
        line-height: 1rem;
    }
}


.raf-badge-circle {
    min-width: 1rem;
    height: 1rem;
    min-height: 1rem;
    border-radius: 1rem;
    color: #FFF;
    font-size: 10px;
    font-style: normal;
    font-weight: 500;
    line-height: 1rem;
    display: flex;
    align-items: center;
    justify-content: center;
}

.raf-badge-tertiary-circle {
    background-color: var(--surface-brand-tertiary-semi-dark);
    filter: drop-shadow(0px 12px 24px rgba(91, 57, 240, 0.16));
}

.sv_progress-toc--mobile {
    position: absolute !important;
    top: 0.5rem !important;
    right: 0.5rem !important;
    visibility: hidden;
}

.sv-popup__body-footer-item.sv-popup__button.sd-btn {
    box-sizing: border-box;
}

.client_details {
    &_collapse_btn {
        position: absolute;
        left: 0.25rem;
        z-index: 2000;
        top: 3rem;
    }

    &_expanded_btn {
        position: absolute;
        right: 0.25rem;
        z-index: 1;
        top: 1.375rem;

        & .e-btn.e-primary {
            background-color: #fff;
        }
    }
}

.page_logo_heading_img_div {
    max-height: 66px;
    height: 66px;
}

@media (min-width: 768px) {
    .listPage {
        &__content {
            padding: 0.75rem;
        }
    }
}

@media (min-width: 992px) {
    .listPage {
        &__content {
            padding: 1rem;
        }
    }
}