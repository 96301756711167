.raf-card.e-card {
    border: 1px solid #ddd;
    padding: 0;

    & .sky_panelHeader {
        border: 0px;
    }

    &:hover {
        border: 1px solid #ddd;
        padding: 0;
    }
}