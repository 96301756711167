.divider {
    margin-top: 20px;
    margin-bottom: 10px;
    width: 100%;

    & h2 {
        display: flex;
        white-space: nowrap;
        font-size: 1em;
        font-weight: 700;
        font-size: 0.75rem;

        &:after,
        &:before {
            content: '';
            display: inline-block;
            position: relative;
            top: 6px;
            width: 100%;
            height: 1px;
            background-color: #b8b8b8;
        }

        &:before {
            right: 1.8%;
        }

        &:after {
            left: 1.8%;
        }
    }
}