//Font style figma start

.button_default_font {
    font-size: 1rem;
    line-height: 1.5rem;
    font-weight: 700;
}

.button_small_font {
    font-size: 0.75rem;
    line-height: 1rem;
    font-weight: 700;
}

.header_1 {
    font-size: 3rem;
    line-height: 3.5rem;
    color: var(--content-neutral-dark);
    font-weight: 600;
    font-family: "Montserrat";
}

.header_2 {
    font-size: 2.5rem;
    line-height: 3rem;
    color: var(--content-neutral-dark);
    font-weight: 600;
    font-family: "Montserrat";
}

.header_3 {
    font-size: 2rem;
    line-height: 2.5rem;
    color: var(--content-neutral-dark);
    font-weight: 600;
    font-family: "Montserrat";
}

.header_4 {
    font-size: 1.5rem;
    line-height: 2rem;
    color: var(--content-neutral-dark);
    font-weight: 600;
    font-family: "Montserrat";
}

.header_5 {
    font-size: 1.25rem;
    line-height: 1.75rem !important;
    color: var(--content-neutral-dark);
    font-weight: 600;
    font-family: "Montserrat";
}

.header_6 {
    font-size: 1.125rem;
    line-height: 1.5rem;
    color: var(--content-neutral-dark);
    font-weight: 600;
    font-family: "Montserrat";
}

.ecllipseFirstLine.subtitle_1,
.subtitle_1,
.ecllipseFirstLine.subtitle_1_light,
.subtitle_1_light {
    font-size: 1rem;
    line-height: 1.5rem !important;
    color: var(--content-neutral-dark);
    font-weight: 500;
    font-family: "Montserrat";
}

.ecllipseFirstLine.subtitle_2,
.subtitle_2,
.ecllipseFirstLine.subtitle_2_light,
.subtitle_2_light {
    font-size: 0.875rem;
    line-height: 1.25rem;
    color: var(--content-neutral-dark);
    font-weight: 500;
    font-family: "Montserrat";
}

.ecllipseFirstLine.subtitle_3,
.subtitle_3,
.ecllipseFirstLine.subtitle_3_light,
.subtitle_3_light {
    font-size: 0.75rem;
    line-height: 1rem;
    color: var(--content-neutral-dark);
    font-weight: 500;
    font-family: "Montserrat";
}

.ecllipseFirstLine.subtitle_1_light,
.subtitle_1_light,
.ecllipseFirstLine.subtitle_2_light,
.subtitle_2_light,
.ecllipseFirstLine.subtitle_3_light,
.subtitle_3_light {
    color: var(--content-neutral-dark);
}

.ecllipseFirstLine.body_1,
.body_1,
.ecllipseFirstLine.body_1_dark,
.body_1_dark,
.ecllipseFirstLine.body_1_light,
.body_1_light,
.ecllipseFirstLine.body_1_dark_bold,
.body_1_dark_bold {
    font-size: 1rem;
    line-height: 1.5rem;
    color: var(--content-neutral-dark);
    font-weight: 400 !important;
}

.ecllipseFirstLine.body_2,
.body_2,
.ecllipseFirstLine.body_2_light,
.body_2_light,
.ecllipseFirstLine.body_2_dark,
.body_2_dark,
.ecllipseFirstLine.body_2_dark_bold,
.body_2_dark_bold {
    font-size: 0.875rem;
    line-height: 1.25rem;
    color: var(--content-neutral-dark);
    font-weight: 400;
}

.ecllipseFirstLine.body_3,
.body_3,
.ecllipseFirstLine.body_3_dark,
.body_3_dark,
.ecllipseFirstLine.body_3_light,
.body_3_light,
.ecllipseFirstLine.body_3_dark_bold,
.body_3_dark_bold {
    font-size: 0.75rem !important;
    line-height: 1rem !important;
    color: var(--content-neutral-dark);
    font-weight: 400;
}

.ecllipseFirstLine.body_3_dark,
.body_3_dark,
.ecllipseFirstLine.body_3_dark_bold,
.body_3_dark_bold,
.ecllipseFirstLine.body_2_dark,
.body_2_dark,
.ecllipseFirstLine.body_2_dark_bold,
.body_2_dark_bold,
.ecllipseFirstLine.body_2_dark,
.body_1_dark,
.ecllipseFirstLine.body_2_dark_bold,
.body_1_dark_bold {
    color: var(--content-neutral-dark);
}

.ecllipseFirstLine.body_3_light,
.body_3_light,
.ecllipseFirstLine.body_2_light,
.body_2_light,
.ecllipseFirstLine.body_1_light,
.body_1_light {
    color: var(--content-neutral-base);
}

.ecllipseFirstLine.body_3_dark_bold,
.body_3_dark_bold,
.ecllipseFirstLine.body_2_dark_bold,
.body_2_dark_bold,
.ecllipseFirstLine.body_2_dark_bold,
.body_1_dark_bold {
    font-weight: 500 !important;
}

.caption {
    font-size: 0.625rem;
    line-height: 0.875rem;
    color: var(--content-neutral-base);
    font-weight: 400;
}

.extra_bold {
    font-weight: 800 !important;
}

.bold {
    font-weight: 700 !important;
}

.semi_bold {
    font-weight: 600 !important;
}

.medium {
    font-weight: 500 !important;
}

.regular {
    font-weight: 400 !important;
}

.italic {
    font-style: italic;
}

//Font style figma end

//Font style start


.header-text {
    font-size: 1rem;
    font-weight: 500;
    color: var(--text-block-grey-color);
    line-height: 1.5rem;
}

.header-text-light {
    font-weight: 400;
}

.header-text-without-color {
    font-size: 1rem;
    font-weight: 500;
}

.header-text-lg {
    font-size: 1.125rem;
    font-weight: 500;
    color: var(--text-block-grey-color);
}

.header-text-normal {
    font-size: 1.3125rem;
    font-weight: 500;
    color: var(--text-block-grey-color);
}

.header-text-xl {
    font-size: 1.5rem;
    font-weight: 500;
    color: var(--text-block-grey-color);
}

.header-text-xl-without-color {
    font-size: 1.5rem;
    font-weight: 500;
    color: inherit;
}

.header-text-sm {
    font-size: 0.875rem;
    font-weight: 500;
    color: var(--text-block-grey-color);
    line-height: 1.25rem;
}

.header-text-sm.ecllipseFirstLine {
    line-height: 1.25rem !important;
}

.header-text-sm-without-color {
    font-size: 0.875rem;
    font-weight: 500;
    color: inherit;
}

.header-text-sm-light {
    font-size: 0.875rem;
    font-weight: 400;
    color: var(--text-block-color);
    line-height: 1.25rem;
}

.header-text-sm-light-without-color {
    font-size: 0.875rem;
    font-weight: 400;
    color: inherit;
}

.secondary-header-text-lg {
    font-size: 0.875rem;
    font-weight: 400;
    color: #666;
}

.secondary-header-text {
    font-size: 0.8125rem;
    font-weight: 400;
    color: #666;
}

.secondary-header-text-dark {
    font-size: 0.8125rem;
    font-weight: 400;
    color: var(--text-block-color);
}

.secondary-header-text-withoutColor {
    font-size: 0.8125rem;
    font-weight: 400;
    color: inherit;
}

.secondary-header-bold-text {
    font-size: 0.8125rem;
    font-weight: 500;
    color: var(--text-block-grey-color);
}

.secondary-header-bold-text-withoutColor {
    font-size: 0.8125rem;
    font-weight: 500;
    color: inherit;
}

.secondary-header-text-lg {
    font-size: 0.875rem;
    font-weight: 400;
    color: #666;
}

.secondary-text {
    font-size: 0.75rem !important;
    font-weight: 400;
    color: #666;
}

.secondary-text-without-color {
    font-size: 0.75rem !important;
    font-weight: 400;
    color: inherit;
}

.secondary-text-bold {
    font-size: 0.75rem !important;
    font-weight: 500;
    color: var(--text-block-grey-color);
}

.secondary-text-sm {
    font-size: 0.625rem !important;
    font-weight: 400;
    line-height: normal;
}

.text-inherit {
    font-size: inherit;
}

.remove-color,
.color_inherit {
    color: inherit !important;
}

.line_height_inherit {
    line-height: inherit !important;
}

.header-icon {
    font-size: 1.125em;
}

//Font style end

.text__Green {
    color: #09a64d;
}

.text__Green1 {
    color: #19b84c;
}

.text__Green-2 {
    color: #22bb74;
}

.text__darkGreen {
    color: #0e4f1f;
}

.text_tertiary {
    color: var(--content-brand-tertiary-base) !important;
}

.text__success {
    color: var(--raf-text-success);
}

.text__grey {
    color: var(--text-grey-color);
}

.text__secondary__grey {
    color: var(--bs-secondary-text-emphasis) !important;
}

.text__Red {
    color: #b2302a;
}

.text__Red-1 {
    color: #ff601b;
}

.text__Red_pin {
    color: #CA2020;
}

.text-warning-1 {
    color: #ffa900;
}

.text_orange {
    color: #F77025 !important;
}

.text-blue {
    color: #0078D7 !important;
}

// background color

.bg-dark-grey {
    background-color: #d1d2d3;
}

.bg-light-grey {
    background-color: #fafafa !important;
}

.bg-grey-1 {
    background-color: #f1f5fa;
}

.bg-body-grey {
    background-color: #f4f5f6;
}

.bg-grey-2 {
    background-color: #f5f9fa !important;
}

.bg-grey-3 {
    background-color: #F3F2EE !important;
}

.bg-grey-4 {
    background-color: #f0f0f0 !important;
}

.bg-rgba-grey-1 {
    background-color: rgba(0, 0, 0, 0.05);
}

.bg-light-blue {
    background-color: #dcecf9;
}

.bg-light-blue-1 {
    background-color: #d1e8f9;
}

.bg-light-blue-2 {
    background-color: #f5f9fa !important;
}

.bg-light-blue-3 {
    background-color: #e9ecf5 !important;
}

.bg-light-blue-3-opacity {
    background-color: #e9ecf580 !important;
}

.bg-raf-header {
    background-color: #d2e4ed;
}

.bg-card-header-1 {
    background-color: #cfe0e5 !important;
}

.bg-card-header-2 {
    background-color: #a6c2cb !important;
}

.bg-card-header-3 {
    background-color: #7da4ae !important;
}

.bg-card-header-4 {
    background-color: #548697 !important;
}

.bg-card-header-5 {
    background-color: #e4eff6 !important;
}

.bg-card-header-6 {
    background-color: #f2f7fb !important;
}

.bg-card-header-8 {
    background-color: #cfd5eb !important;
}

.bg-card-header-8 {
    background-color: #cfd5ebaa !important;
}

.bg-card-header-9 {
    background-color: rgba(207, 213, 235, 0.4) !important;
}

.bg-rgba-white {
    background-color: rgba(255, 255, 255, 0.5);
}


//Font weight style start
.text-light-bold {
    font-weight: 500;
}

//Font weight style end

.fa-face-frown-open {
    color: #d83d05;
}

.fa-face-smile-relaxed {
    color: #f15a23;
}

.fa-face-grin-squint {
    color: #fec81f;
}

.fa-face-smile {
    color: #6ec32a;
}

.fa-face-laugh-squint {
    color: #3b7f06;
}