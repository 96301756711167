/* Side Navigation css styles*/
.sidebar-nav {
    // position: relative;
    //position: fixed;
    left: 0;
    top: 0;
    bottom: 0;
    //width: 220px;
    overflow: visible;
    background: transparent !important;
    flex-wrap: nowrap !important;
    //box-shadow: 0 0em 0.3125em 0 rgba(0,0,0,0.16), 0 0em 0.625em 0 rgba(0,0,0,0.12);
    display: flex;
    flex-direction: column;
    // visibility: unset !important;
    // transform: translateX(0) !important;
    /*box-shadow: 0 0.0625em 0.25em 0 rgba(0, 0, 0, 0.10), 0 0em 0.0625em 0 rgba(0, 0, 0, 0.18) !important;*/
    font-family: "Roboto";
}

.sidebar-nav .e-control {
    font-family: "Roboto";
}

.sidebar-nav.e-sidebar.e-over {
    box-shadow: unset;
}

/*.toggle-button-outer {
    position: absolute;
    top: 1.25em;
    left: calc(100% - 17px);
    height: 35px;
    width: 35px;
    border-radius: 50%;*/
/* border-top-right-radius: 25px; */
/* border-bottom-right-radius: 25px; */
/*background-color: #0078d6;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 100;
}
.toggle-button-outer .e-btn {
    color: #fff;
}
.sidebar-nav.mini-navbar .toggle-button-outer {
    left: calc(100% - 0.8125em);
}
.toggle-button-outer .sideBar-toggle-button {
    margin: 0 !important;
}
.sidebar-nav .toggle-button-outer .sideBar-toggle-button .fa-bars {
    margin: 0 0 0 0.625em !important;
    display: none;
}
.sidebar-nav.mini-navbar .toggle-button-outer .sideBar-toggle-button .fa-times {
    margin: 0 0 0 0em !important;
    display: none;
}
.sidebar-nav .toggle-button-outer .sideBar-toggle-button .fa-times {
    margin: 0 0 0 0em !important;
    display: block;
}
.sidebar-nav.mini-navbar .toggle-button-outer .sideBar-toggle-button .fa-bars {
    margin: 0 0 0 0.625em !important;
    display: block;
}*/
/*.sidebar-nav.mini-navbar {
    width: 70px !important;
}*/
.sidebar-nav>li>a {
    color: #676a6c;
    font-size: 0.875em;
    padding: 0.75em 1.25em 0.75em 0.875em;
    white-space: nowrap;
    overflow: hidden;
    display: block;
    // border-left: 0.25em solid transparent !important;
}

.sidebar-nav>li>a:hover,
.sidebar-nav>li>a:focus,
.sidebar-nav>li>a.active {
    background-color: #f9f9f9;
    color: #3E9CFB;
}

.sidebar-nav>li>a.active {
    font-weight: 500;
    border-left: 0.25em solid #3E9CFB !important;
}

.sidebar-nav>li>a i {
    font-size: 1.125em;
    margin-right: 0.125em;
    width: 36px;
    vertical-align: middle;
    text-align: center;
}

.sidebar-nav.mini-navbar>li>a .nav-label {
    display: inherit;
    font-size: 0.625em;
    vertical-align: middle;
}

.sidebar-nav.mini-navbar>li>a {
    padding: 0.3125em;
    text-align: center;
}


.sidebar-nav.mini-navbar>li>a i {
    margin-right: 0em;
}

.logoShortImage {
    height: 36px;
    max-width: 100%;
    margin-top: 0.625em;
}

.prodLogoImage {
    /*height: 39px;*/
    /* height: 35px;*/
    width: 75%;
    //margin: 0 0.625em;
    max-width: 170px;
    /*max-width: 100%;*/
}

.sidebar-nav .prodShortLogoImage {
    display: none;
}

.sidebar-nav.mini-navbar .prodShortLogoImage {
    display: block;
}

/*.sidebar-nav.mini-navbar .prodLogoImage {
    display: none;
}*/

.brandLogoDiv {
    /*height: 60px;*/
    //min-height: 80px;
    width: 100%;
    /*padding-top: 0.75em;
    padding-bottom: 0.75em;*/
    //padding-top: 1.25em;
    //padding-bottom: 0.9375em;
    // margin-bottom: 0.25em;
    //box-shadow: 0 0.375em 0.375em -0.375em rgba(0,0,0,0.16);
    // border-bottom: 0.0625em solid #45525F;
    // border-bottom: 1px solid #004d8e;
    //border-bottom: 0.125em solid #eaeaea;
    position: relative;
    // z-index: 100;
    //background: #fff;
    //background: #181f48;
    // background: #142656;
    // align-items: center;
    min-height: 60px;
    padding: 1.25rem 1rem 2rem 0.5rem;
    display: flex;
    align-items: center;
    min-height: 80px;

    // min-height: 80px;
    &.raf-lg {
        min-height: 80px;
        height: 80px;
    }
}

// .brandLogoDiv.brandLogoDiv-minimized {
//     display: flex !important;
// }

// .e-sidebar.sidebar-nav .brandLogoDiv.brandLogoDiv-maximized {
//     display: none !important;
// }
.e-sidebar.sidebar-nav .brandLogoDiv.brandLogoDiv-minimized {
    display: none !important;
}

.e-sidebar.sidebar-nav.mini-navbar .brandLogoDiv.brandLogoDiv-maximized {
    display: none !important;
}

.e-sidebar.sidebar-nav.mini-navbar .brandLogoDiv.brandLogoDiv-minimized {
    display: flex !important;
}

.brand-second-logo-div {
    height: 35px;
    margin-top: 0.4375em;
}

.profileDiv {
    display: flex;
    align-items: center;
    // justify-content: center;
    //padding: 1.25rem 0.3125em;
    //border-bottom: 0.125em solid #e3e3e7;
    margin-top: 0em;
    min-height: 40px;
    border: 0.0625em solid #004d8e;
    border-left: 0;
    border-right: 0;
    cursor: pointer;
    color: #fff;
    //padding: 0 1.25em;
    padding: 0 0.75rem;
    // padding: 0 0.6em;
    // background: #142656;
}

.moduleNameDiv.profileDiv {
    cursor: unset;
}

.profileDiv.profileDiv-maximized~.profileDiv.profileDiv-maximized {
    border-top: 0px;
}

.profileDiv:hover {
    //background-color: #fcfcfc;
    //background-color: #0f1634;
}

.profileDiv-item {
    font-size: 13px;
    line-height: normal;
}

.profileDiv-item-text-secondary {
    font-size: 11px;
    color: rgba(255, 255, 255, 0.5);
}

//Push Menu styles
.rpm-mp-menu {
    position: fixed !important;
    top: 60px !important;
    overflow-y: auto;
    padding-bottom: 1.875em;
}

.rpm-mp-menu.mini-navbar {
    width: 0em !important;
}

.rpm-mp-header {
    margin-bottom: 0em !important;
}

.rpm-header {
    cursor: pointer;
    margin-top: 0.3125em;
    margin-bottom: 0.3125em;
}

.rpm-mp-header>i {
    font-size: 1em;
}

.rpm-mp-header img {
    width: 22px;
    margin-right: 0.625em;
}

.rpm-node-exp {
    color: #676a6c !important;
}

/*.rpm-mp-header > i:hover{
    background-color:red;
    padding:0.3125em;
}*/
.rpm-mp-level {
    /*background: #37474F !important;*/
    background: #fff !important;
    padding-left: 0em !important;
}

.rpm-mp-level .rpm-node-cntr>a {
    padding-left: 1.25em !important;
    font-size: 0.875em;
    /*color: #f7f7f7 !important;*/
    color: #676a6c !important;
}

.rpm-mp-level-1 .rpm-node-cntr {
    padding: 0em;
}

.rpm-mp-level-1 .rpm-node-cntr>a {
    padding-left: 0.625em !important;
    font-size: 0.875em;
    /*color: #f7f7f7 !important;*/
    color: #676a6c !important;
    padding: 0.625em 0em;
}

.rpm-node-cntr:hover {
    cursor: pointer;
    /*background-color: #263238;*/
    background-color: #f8f8f9;
    opacity: 1;
    font-weight: 500;
    color: #3E9CFB !important;
}

.rpm-mp-level-0 .rpm-node-cntr.active {
    cursor: pointer;
    /*background-color: #263238;*/
    background-color: #f8f8f9;
    opacity: 1;
    font-weight: 500;
    color: #3E9CFB;
    font-weight: 500;
    border-left: 0.25em solid #3E9CFB !important;
}

.rpm-mp-level-1 .rpm-node-cntr.active {
    cursor: pointer;
    /*background-color: #263238;*/
    background-color: #f8f8f9;
    opacity: 1;
    font-weight: 500;
}

.rpm-mp-level .rpm-node-cntr.active>a,
.rpm-mp-level .rpm-node-cntr:hover>a {
    color: #3E9CFB !important;
}


.rpm-mp-level-1 .rpm-mp-header {
    font-size: 1em !important;
    margin-bottom: 0em !important;
    font-weight: 500;
    text-transform: uppercase;
    letter-spacing: 0.0625em;
    font-size: 1em;
    margin: 0;
    padding: 1em;
    /*color: #fff;*/
    color: #676a6c;
    text-shadow: 0 0 0.0625em rgba(0, 0, 0, 0.1);
}

.rpm-mp-level-1 .rpm-mp-back {
    background: rgba(0, 0, 0, 0.1);
    outline: none;
    color: #fff;
    text-transform: uppercase;
    letter-spacing: 0.0625em;
    font-weight: 700;
    display: block;
    font-size: 0.625em;
    padding: 1em 0.50em;
    position: relative;
    box-shadow: inset 0 0.0625em rgba(0, 0, 0, 0.1);
}

.rpm-mp-level-1 .rpm-mp-back:hover {
    background-color: rgba(0, 0, 0, 0.2);
}

.rpm-mp-level .rpm-mp-back>svg {
    font-size: 1em;
}

.rpm-mp-level-1 .quickViewTitle {
    font-size: 0.8125em;
    margin-bottom: 0em !important;
    font-weight: 500;
    letter-spacing: 0.0625em;
    margin: 0;
    padding: 1em;
    /*color: #fff;*/
    color: #676a6c;
    margin-bottom: 0em !important;
    font-weight: 500;
}

.rpm-mp-level-0 .quickViewTitle {
    margin-bottom: 0em !important;
}

.rpm-mp-level .card {
    background: transparent;
    border: none;
    box-shadow: none;
}

.rpm-mp-level .card .card-header {
    padding: .75rem 1rem;
    border: none;
    /*color: #fff;*/
    color: #676a6c;
    font-size: 0.8125em;
    /*background-color: rgba(0,0,0,0.2);*/
    background-color: rgba(0, 0, 0, .05);
    cursor: pointer;
    margin-bottom: 0.03125em;
    font-weight: 500;
}

.rpm-mp-level .card .card-header>a>svg {
    font-size: 1.5em;
    font-weight: 700;
    color: #676a6c;
}

.rpm-mp-level .card .card-header>div>svg {
    font-size: 1.125em;
    margin-right: 0.3125em;
}

.rpm-mp-level .card .card-header>a {
    display: contents;
}

.rpm-mp-level .rpm-node-cntr>a>span {
    min-width: 32px;
    text-align: center;
    font-size: 1.125em;
    padding-right: 0.625em;
}

//New style changes start

.sidebar-content {
    list-style: none;
    margin: unset;
    padding: unset;
    //margin-top: 0.625em;
    //transition: all 0.3s ease-in-out;
}

.sidebar-content.sidebar-content-minimized {
    display: none;
}

.e-sidebar.sidebar-nav .sidebar-content.sidebar-content-maximized {
    display: none;
}

.e-sidebar.sidebar-nav.mini-navbar .sidebar-content.sidebar-content-maximized {
    display: none;
}

.e-sidebar.sidebar-nav .sidebar-content.sidebar-content-minimized {
    display: block;
}

.sidebar-nav .customScrollBar::-webkit-scrollbar-thumb {
    background-color: transparent;
    display: none;
}

.sidebar-nav:hover .customScrollBar::-webkit-scrollbar-thumb {
    background-color: #c8c8c8;
}

.sidebar-nav .customScrollBar::-webkit-scrollbar {
    width: 0;
    height: 0;
}

// .sidebar-nav .e-sidebar__right .customScrollBar::-webkit-scrollbar {
//     width: 8px;
//     height: 8px;
// }

.main-menu-li button {
    background-color: #06b2bb;
}

.main-menu-li button {
    font-size: 0.625em !important;
}

.main-menu-li {
    position: fixed;
    bottom: 0;
    right: 0;
    z-index: 100;
    padding: 0.625em;
    background-color: #142656;
    left: 0;
    display: flex;
    justify-content: center;
    text-transform: uppercase;
}

.sidebar-content .menu-item {
    cursor: pointer;
    overflow: hidden;
    border-left: 0.25em solid #181f48;
}

.sidebar-content .menu-item .menu-item {
    border-left: 0em solid transparent;
}

.menu-item-uppercaseHeader {
    text-transform: uppercase;
    font-size: 0.75em;
    justify-content: flex-start !important;
}

.menu-item-uppercaseHeader .down-arrow:before {
    left: 1.5em;
    top: calc(50% - 0.1875em) !important;
}

.sidebar-content .menu-item .menu-item .menu-item-text {
    padding-left: 2.1875em;
}

/*.sidebar-content .menu-item .menu-item .menu-item .menu-item-text {
    padding-left: 60px;
}*/
.sidebar-content .menu-item .menu-item .menu-item .menu-item-text .icon-ellipsis-horizontal {
    display: none;
}

.sidebar-content .menu-item .menu-item .menu-item .menu-item-text.active:hover .icon-ellipsis-horizontal {
    display: block;
}

.sidebar-content .menu-item .menu-item-text {
    //background-color: #fff;
    //background-color: #181f48;
    //color: #676a6c;
    //color: #fff;
    padding: 0.9375em 0;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    position: relative;
    background: #142656;
    z-index: 100;
    color: #c8cce3;
    font-family: "Roboto";
    font-size: 1em;
    text-align: center;
}

.sidebar-content .menu-item .menu-item-text .menu-item-text-content {
    //font-size: 0.8125em;
    font-size: 0.75em;
    margin-top: 0.25rem;
}

.sidebar-content .menu-item .menu-item-text .e-control {
    font-family: 'Roboto';
    font-size: 0.8125em;
}

.sidebar-content .icon-ellipsis-dot,
.sidebar-content .icon-ellipsis-dot:before,
.sidebar-content .icon-ellipsis-dot:after {
    background-color: #333;
    width: 0.25em;
    height: 0.25em;
    left: calc(50% - 0.125em);
}

.sidebar-content .icon-ellipsis-horizontal .icon-ellipsis-dot:before {
    top: 0.375em;
}

.sidebar-content .icon-ellipsis-horizontal:hover .icon-ellipsis-dot:before {
    top: -0.375em;
}

.sidebar-content .icon-ellipsis-horizontal .icon-ellipsis-dot:after {
    bottom: 0.375em;
}

.sidebar-content .icon-ellipsis-horizontal:hover .icon-ellipsis-dot:after {
    bottom: -0.375em;
}

.sidebar-content .menu-item .menu-item-text .down-arrow:before {
    transform: rotate(-45deg);
    transition: all 0.3s ease-in-out;
    font-size: 0.8125em;
    left: 0;
    border-color: #fff;
}

.sidebar-content .menu-item.active .menu-item-text .down-arrow:before {
    transform: rotate(45deg);
    top: calc(50% - 0.375em);
    left: 0;
}

.sidebar-content .menu-item.active .menu-item-text {
    font-weight: 500;
    //color: #000;
    color: #fff;
}

.sidebar-content .menu-item .menu-item .menu-item-text .down-arrow:before {
    transform: rotate(-45deg);
    transition: all 0.3s ease-in-out;
}

.sidebar-content .menu-item .menu-item .menu-item-text {
    color: #676A6C;
    font-weight: 400;
    background-color: #fff;
    padding: 0.7rem 1rem;
    height: 40px;
}

.sidebar-content .menu-item.active .menu-item.active .menu-item-text .down-arrow:before {
    transform: rotate(45deg);
    top: calc(50% - 0.3125em) !important;
}

.sidebar-content .menu-item.active .menu-item.active .menu-item-text {
    //color: #676A6C;
    color: #fff;
    font-weight: 400;
    background-color: #fbfbfb;
}

.sidebar-content .menu-item.active .menu-item.active .menu-item-text.active .e-control {
    font-weight: 500;
}


.sidebar-content .menu-item.active .menu-item.active .menu-item .menu-item-text.menu-views {
    border-left: 0.25em solid transparent;
    //background-color: #fff;
    padding-left: 1.625em !important;
    /*background-color: #181f48;*/
    background-color: #142656;
}

.sidebar-content .menu-item.active .menu-item-text {
    background-color: #141d45;
}

.sidebar-content .menu-item.active .menu-item.active .menu-item .menu-item-text.menu-views {
    background-color: #141d45;
}

.sidebar-content .menu-item.active .menu-item.active .menu-item-text.menu-views.active {
    //color: #2296f4 !important;
    //background-color: #e3f2fd !important;
    background-color: #0f1634 !important;
    color: #F4F7FA;
    /*border-left: 0.25em solid #2296f4 !important;*/
}

/*.sidebar-content .menu-item-text.menu-views.active:hover .e-control {
    color: #2296f4 !important;
}*/

.sidebar-content .menu-item .sidebar-content {
    /*transform: translate(0,-100%);
    transition: all 0.3s ease-in-out;*/
    -webkit-transition: opacity 2s ease-in;
    -moz-transition: opacity 2s ease-in;
    -o-transition: opacity 2s ease-in;
    -ms-transition: opacity 2s ease-in;
    transition: opacity 2s ease-in;
    height: 0;
    overflow: hidden;
    margin: 0;
}

.sidebar-content .menu-item.active .sidebar-content {
    /*transform: translate(0,0%);*/
    overflow: hidden;
    height: 100%;
    margin: 0;
}

.sidebar-content .menu-item .menu-item .sidebar-content {
    /*transform: translate(0,-100%);
    transition: all 0.3s ease-in-out;*/
    -webkit-transition: opacity 2s ease-in;
    -moz-transition: opacity 2s ease-in;
    -o-transition: opacity 2s ease-in;
    -ms-transition: opacity 2s ease-in;
    transition: opacity 2s ease-in;
    height: 0;
    overflow: hidden;
    margin: 0;
}

.sidebar-content .menu-item.active .menu-item.active .sidebar-content {
    /*transform: translate(0,0);*/
    overflow: hidden;
    height: 100%;
    margin: 0;
}

.leftMenuDropdownPopup .e-item,
.leftMenuDropdownPopup .e-menu-icon {
    font-size: 0.75em !important;
}

.sidebar-content .menu-item-text:hover {
    //background-color: #f8f8f8 !important;
    //color: #5b5d5f !important;
    color: #F4F7FA !important;
    font-weight: 500;
}

.sidebar-content .menu-item-text:hover .e-control {
    //background-color: #f8f8f8 !important;
    //color: #5b5d5f !important;
    //color: #F4F7FA !important;
    font-weight: 500;
}

.sidebar-content .menu.menu-item.active .menu-item-text,
.sidebar-content .menu.menu-item.active .menu-item.active .menu-item .menu-item-text.menu-views {
    //background: #f5f5f5;
}

.sidebar-content .menu.menu-item.active.no-childMenu .menu-item-text {
    //background-color: #e3f2fd;
    background-color: #0f1634;
}


.sidebar-content .menu.menu-item.active {
    /*border-left: 0.25em solid #2296f4 !important;*/
    border-left: 0.1875em solid #16C2F2 !important;
}

.sidebar-content .menu-item-text .leftMenuDropdownPopup.e-dropdown-btn {
    display: none;
}

.sidebar-content .menu-item-text:hover .leftMenuDropdownPopup.e-dropdown-btn {
    display: block;
}

.sidebar-footer {
    background: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    border-top: 0.0625em solid #eaeaea;
    z-index: 102;
    width: 100%;
    padding: 0.625em 0;
}

/*.e-dropdown-btn .e-icon-right.e-caret {
    margin-top: 0.25em;
}*/

.e-dropdown-btn.userProfileName:hover {
    background: transparent !important;
}

.e-dropdown-btn.userProfileName {
    background: transparent !important;
    border: none;
    padding: 0;
    display: flex;
    align-items: center;
    //width: 220px;
    font-weight: 500;
    //color: #666;
    color: #fff;
    overflow: hidden;
}

.e-dropdown-btn.userProfileName span {
    //overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.sidebar-footer .ddbtnUserProfileCard {
    min-width: 42px;
    min-height: 42px;
    width: 42px;
    height: 42px;
    overflow: hidden;
    border-radius: 50%;
    background-color: #ddd;
    //padding-left: 0.8125em;
    padding-top: 0.375em;
    margin-right: 0.625em;
    font-size: 1.125em;
}

.topNavBar .ddbtnUserProfileCard {
    min-width: 30px;
    min-height: 30px;
    width: 30px;
    height: 30px;
    overflow: hidden;
    border-radius: 50%;
    background-color: #ddd;
    //padding-left: 0.8125em;
    padding-top: 0.0625em;
    //margin-right: 0.625em;
    font-size: 1em;
}

.topNavBar .e-dropdown-btn .ddbtnUserProfileCard .e-btn-icon {
    font-size: 0.8125em;
}

.e-sidebar.sidebar-nav.mini-navbar {
    border: unset;
    //transform: translateX(-100%) !important;
    width: 65px !important;
}

.userTeam_menu_Items .menu-item.menu {
    background: #f3f3f3;
}

.userTeam_menu_Items .menu-item .menu-item-text.header {
    //background: #f3f3f3;
}

.userTeam_menu_Items .menu-item-text.header:hover {
    //color: #8D90A6 !important;
}

.menu-item-text .e-badge-warning {
    background-color: #f78828 !important;
    color: #fff !important;
}

.e-sidebar.sidebar-nav .menu-item .fa {
    font-size: 0.75em !important;
    width: unset !important;
}

.sidebar-content .menu-item .menu-item-text.menu-views {
    justify-content: left !important;
}

.sidebar-content .menu-item .menu-item-text.menu-views .fa-icon {
    font-size: 0.625em;
    color: #ffa726 !important;
    width: 0.75em;
}

// .profileDiv.profileDiv-minimized {
//     display: flex;
//     justify-content: center;
// }

// .e-sidebar.sidebar-nav .profileDiv.profileDiv-maximized {
//     display: none;
// }
.e-sidebar.sidebar-nav .profileDiv.profileDiv-minimized {
    display: none;
}

.e-sidebar.sidebar-nav.mini-navbar .profileDiv.profileDiv-maximized {
    display: none;
}

.e-sidebar.sidebar-nav.mini-navbar .profileDiv.profileDiv-minimized {
    display: flex;
}

.portal-btn-col {
    width: calc(100% - 56px);
}

//List component style start
.e-sidebar.sidebar-nav .e-list-parent .e-list-item {
    padding: 0;
    height: auto;
}

.e-sidebar.sidebar-nav .e-list-parent .e-list-item a {
    text-decoration: inherit;
    color: inherit;

}

.e-sidebar.sidebar-nav .e-listview .e-list-parent .e-list-item:hover,
.e-sidebar.sidebar-nav .e-listview .e-list-parent .e-list-item.e-hover,
.e-sidebar.sidebar-nav .e-listview .e-list-parent .e-list-item.e-active {
    background-color: #0f1634 !important;
    color: #fff !important;
    // border-radius: 4px;
}

.e-sidebar.sidebar-nav .e-list-parent .e-list-item .menu-item-text {
    padding: 0.25rem 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-bottom: 0.25rem;
}

.e-sidebar.sidebar-nav .e-template-list__outter .e-list-parent .e-list-item .menu-item-text {
    justify-content: center;
}


.e-sidebar.sidebar-nav .e-list-parent .e-list-item .menu-item-text .menu-item-text-content {
    line-height: normal;
    // font-size: 0.875em;
    font-size: 0.875rem;
}

.e-sidebar.sidebar-nav .e-list-parent .e-list-item .menu-item-text .menu-item-text-content.e-template-list__text {
    font-size: 0.625rem;
    margin-top: 0.25rem;
}

.e-sidebar.sidebar-nav.isNotDevice.mini-navbar .e-list-parent .e-list-item .menu-item-text .menu-item-text-content {
    display: none;
}

.e-sidebar.sidebar-nav .e-list-parent .e-list-item:hover .menu-item-text .menu-item-text-content,
.e-sidebar.sidebar-nav .e-list-parent .e-list-item.e-hover .menu-item-text .menu-item-text-content,
.e-sidebar.sidebar-nav .e-list-parent .e-list-item.e-active .menu-item-text .menu-item-text-content {
    color: #fff;
}

.e-sidebar.e-left.e-close.e-dock .prodLogoImage {
    width: 50px;
}

.e-sidebar .brandLogoDiv.brandLogoDiv-minimized {
    display: none !important;
}

.e-sidebar.e-left.e-close.e-dock .brandLogoDiv.brandLogoDiv-maximized {
    display: none !important;
}

.e-sidebar.e-left.e-close.e-dock .brandLogoDiv.brandLogoDiv-minimized {
    display: flex !important;
}

.e-sidebar.sidebar-nav .profileDiv.profileDiv-minimized {
    display: none;
}

.e-sidebar.e-left.e-close.e-dock .profileDiv.profileDiv-maximized {
    display: none;
}

.e-sidebar.e-left.e-close.e-dock .profileDiv.profileDiv-minimized {
    display: flex;
    justify-content: center;
}

.e-sidebar.sidebar-nav.e-left.e-open.e-dock .e-tooltip-wrap.e-popup-open {
    display: none;
}

.e-sidebar.sidebar-nav .e-tooltip-wrap.e-popup-open .e-tip-content {
    white-space: nowrap;
}

//List component style end


//New style changes end
@media only screen and (min-width: 767px) {

    .e-sidebar.sidebar-nav.mini-navbar .e-badge {
        position: absolute;
        top: 0;
        right: 0;
    }
}

@media only screen and (max-width: 767px) {


    // .e-sidebar.sidebar-nav.e-left.e-open.e-dock {
    //     width: 15px !important;
    //     transform: translateX(0) !important;
    // }

    // .e-sidebar.e-left.e-close.e-dock {
    //     width: 80% !important;
    // }

    // //.e-sidebar.sidebar-nav.e-left.e-open.e-dock button.bg-rgba-dark ,
    // .e-sidebar.sidebar-nav.e-left.e-open.e-dock .e-list-parent.e-ul,
    // .e-sidebar.sidebar-nav.e-left.e-open.e-dock .profileDiv.profileDiv-maximized .col,
    // .e-sidebar.sidebar-nav.e-left.e-open.e-dock img {
    //     display: none;
    // }

    // .e-sidebar.sidebar-nav.e-left.e-open.e-dock .profileDiv.profileDiv-maximized {
    //     padding: 0;

    // }

    // .e-sidebar.sidebar-nav.e-left.e-open.e-dock button.bg-rgba-dark {
    //     position: absolute;
    //     top: 85px;
    // }

    // .e-sidebar.sidebar-nav button.bg-rgba-dark {
    //     transform: rotate(180deg);
    // }

    /*.e-sidebar.sidebar-nav .toggle-button-outer {
        left: calc(100% - 0.8125em);
    }
    .sidebar-nav.mini-navbar .toggle-button-outer {
        left: calc(100% - 1.0625em);
    }*/
    .sidebar-nav.mini-navbar .prodLogoImage {
        display: inline-block;
    }

    .sidebar-nav.mini-navbar .prodShortLogoImage {
        display: none;
    }

    .sidebar-nav.mini-navbar>li>a .nav-label {
        display: inline-block;
        font-size: 0.8125em;
    }

    .sidebar-nav.mini-navbar>li>a {
        padding: 0.75em 1.25em 0.75em 0.875em;
        text-align: left;
    }

    .sidebar-nav.mini-navbar>li>a i {
        margin-right: 0.125em;
    }

    /*.sidebar-nav .toggle-button-outer .sideBar-toggle-button .fa-times {
        margin: 0 0 0 0em !important;
        display: none;
    }

    .sidebar-nav.mini-navbar .toggle-button-outer .sideBar-toggle-button .fa-bars {
        margin: 0 0 0 0.625em !important;
        display: none;
    }

    .sidebar-nav .toggle-button-outer .sideBar-toggle-button .fa-bars {
        margin: 0 0 0 0.625em !important;
        display: block;
    }

    .sidebar-nav.mini-navbar .toggle-button-outer .sideBar-toggle-button .fa-times {
        margin: 0 0 0 0em !important;
        display: block;
    }*/
    .rpm-mp-menu {
        width: 0em !important;
    }

    .rpm-mp-menu.mini-navbar {
        width: 80% !important;
        z-index: 1034;
    }

    .sidebar-content .menu-item-text .leftMenuDropdownPopup.e-dropdown-btn {
        display: block;
    }

    .sidebar-content .menu-item .menu-item .menu-item .menu-item-text .icon-ellipsis-horizontal {
        display: none !important;
    }

    .e-sidebar.sidebar-nav.mini-navbar {
        width: 100% !important;
        min-width: 100%;
        position: fixed;
        z-index: 1050 !important;
    }

    .e-sidebar.sidebar-nav.mini-navbar .brandLogoDiv.brandLogoDiv-maximized {
        display: flex !important;
    }

    .e-sidebar.sidebar-nav.mini-navbar .brandLogoDiv.brandLogoDiv-minimized {
        display: none !important;
    }

    .e-sidebar.sidebar-nav.mini-navbar .sidebar-content.sidebar-content-maximized {
        display: block;
    }

    .e-sidebar.sidebar-nav.mini-navbar .sidebar-content.sidebar-content-minimized {
        display: none;
    }

    .e-sidebar.sidebar-nav .profileDiv.profileDiv-maximized {
        display: none;
    }

    .e-sidebar.sidebar-nav.mini-navbar .profileDiv.profileDiv-maximized {
        display: flex;
    }

    .e-sidebar.sidebar-nav.mini-navbar .profileDiv.profileDiv-minimized {
        display: none;
    }

    .navigationMenu-col {
        width: 0px;
        z-index: 1000;
    }

    body.dlg-open .navigationMenu-col {
        z-index: 100;
    }

    .sidebar-content .menu-item .menu-item-text {
        padding-left: 0.5rem;
        padding-right: 0.5rem;
    }
}

@media only screen and (max-width: 576px) {
    .prodLogoImage {
        width: 100%;
    }

    .sidebar-content .menu-item .menu-item-text .menu-item-text-content {
        font-size: 00.875em;
    }
}